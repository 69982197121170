:root {
    --white: #fff;
    --gray-100: #e1e1e6;
    --gray-300: #c4c4cc;
    --gray-400: #8d8d99;
    --gray-600: #323238;
    --gray-700: #29292e;
    --gray-800: #202024;
    --gray-900: #121214;

    --green-300: #00b37e;
    --green-500: #00875f;

    --red-500: #f75a68;

    --blue-300: #58CCE9;
}

:focus {
    outline: transparent;
    box-shadow: 0 0 0 2px var(--blue-300);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}