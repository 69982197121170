.container {
    background-color: #000000;
    color: #61dafb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .logoContainer {
    margin-bottom: 2rem;
  }
  
  .logo {
    width: 20vw;
  }
  
  .message h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .message p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .container {
      background-color: #000000;
      color: #61dafb;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .logoContainer {
      margin-bottom: 2rem;
    }
  
    .logo {
      width: 40vw; 
    }
  
    .message {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .message h1 {
      font-size: 2rem; 
      margin-bottom: 1rem;
      width: 80%;
      text-align: center;
    }
  
    .message p {
      font-size: 1.2rem; 
      margin-bottom: 2rem;
      text-align: center; 
    }
  }
  