.skillsSection {
    text-align: center;
    padding: 2rem;
    /* background-color: #f5f5f5; */
    background-color: black;
    position: relative;
    overflow: hidden;
    margin-top: 5rem;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #58CCE9;
  }
  
  .phoneContainer {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 4rem; 
  }
  
  .phoneImage {
    max-width: 15rem; 
    height: auto;
    border-radius: 32px; 
    position: relative;
    z-index: 1;
  }
  
  .skillsContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none; 
  }
  
  .skill {
    position: absolute;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    pointer-events: auto;
    z-index: 2;
  }
  
  .skill.inView {
    opacity: 1;
    transform: translateY(0);
  }
  
  .skillIcon {
    width: 4rem; 
    height: 4rem;
    margin-right: 0.5rem;
  }
  
  .skillName {
    font-size: 1rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  
  .skill:nth-of-type(1) {
    left: calc(50% - 14rem); 
    top: calc(50% - 14rem); 
  }
  
  .skill:nth-of-type(2) {
    left: calc(50% + 10rem); 
    top: calc(50% - 14rem); 
  }
  
  .skill:nth-of-type(3) {
    left: calc(50% - 14rem); 
    top: calc(50% - 6rem); 
  }
  
  .skill:nth-of-type(4) {
    left: calc(50% + 10rem); 
    top: calc(50% - 6rem); 
  }
  
  .skill:nth-of-type(5) {
    left: calc(50% - 14rem); 
    bottom: calc(50% - 14rem); 
  }
  
  .skill:nth-of-type(6) {
    left: calc(50% + 10rem); 
    bottom: calc(50% - 14rem); 
  }
  
  .skill:nth-of-type(7) {
    left: calc(50% - 14rem); 
    bottom: calc(50% - 6rem); 
  }
  
  .skill:nth-of-type(8) {
    left: calc(50% + 10rem); 
    bottom: calc(50% - 6rem); 
  }
  
  .skill:nth-of-type(9) {
    left: calc(50% - 14rem); 
    bottom: calc(50% - 10rem); 
  }

  .viewAllButton {
    margin-top: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: rgb(0, 0, 0);
    background-color: #58CCE9;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.viewAllButton:hover {
    background-color: #45a8bf;
}

.modal {
  width: 80%;
  max-width: 600px;
  background-color: rgb(0, 0, 0);
  padding: 2rem;
  position: relative;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #58CCE9; 
  z-index: 1001;
  max-height: 80vh; 
  overflow-y: auto; 
}

.modal h2 {
  color: #58CCE9;
  text-align: center;
  margin-bottom: 3rem;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalGridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  max-height: 80vh; 
  overflow-y: auto;
}

.modalSkill {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modalSkillIcon {
  width: 2rem;
  height: 2rem;
}

.closeButton {
  margin-top: 3rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: rgb(0, 0, 0);
  background-color: #58CCE9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
    background-color: #45a8bf;
}
  
  @media (max-width: 768px) {
    .title {
        font-size: 1.8rem; 
    }

    .phoneImage {
        display: none; 
    }

    .skillsContainer {
        width: 100%;
        height: auto; 
        top: 0; 
        left: 0;
        transform: none; 
        position: relative; 
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
        gap: 1rem; 
    }

    .skill {
        width: 3rem; 
        height: 3rem; 
        font-size: 0.8rem; 
        margin: 0.5rem; 
        display: flex;
        flex-direction: column;
        align-items: center; 
        opacity: 0; 
        transform: translateY(100px); 
        transition: opacity 1.5s ease, transform 1.5s ease;
    }

    .skillIcon {
        width: 3rem; 
        height: 3rem; 
    }

    .skillName {
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }

    .skill:nth-of-type(1),
    .skill:nth-of-type(2),
    .skill:nth-of-type(3),
    .skill:nth-of-type(4),
    .skill:nth-of-type(5),
    .skill:nth-of-type(6),
    .skill:nth-of-type(7),
    .skill:nth-of-type(8),
    .skill:nth-of-type(9) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
    }

    .modal {
      width: 90%;
      padding: 1rem;
      max-height: 70vh;
      overflow-y: auto;
    }
  
    .modalGridContainer {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      max-height: 50vh;
    }
  
    .viewAllButton {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  
    .closeButton {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
}
