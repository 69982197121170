.meus-projetos {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    padding: 2vh 10vw;
    background-color: black;
    margin-top: 5rem;
}

.text-projetos {
    text-align: center;
    padding-top: 5vh;
    margin-bottom: 2vh;
    width: 100%;
}

.title {
    font-size: 3rem;
    font-weight: bold;
    color: #58CCE9;
}

.projeto {
    width: 48%;
    margin-bottom: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #61dafb;
    border-radius: 8px;
    padding: 1vh;
    background-color: rgba(255, 255, 255, 0.075);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateX(10vw);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.projeto.aparecer {
    opacity: 1;
    transform: translateX(0);
}

.projeto.direita .imagem-projeto {
    order: 2;
}

.projeto.esquerda .imagem-projeto {
    order: 1;
}

.texto-projeto {
    flex: 1;
    padding: 1vw;
    color: black;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.projeto.aparecer .texto-projeto {
    opacity: 1;
    transform: translateY(0);
}

.texto-projeto h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #61dafb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
}

.texto-projeto p {
    font-size: 1.1rem;
    margin: 0;
    color: #d0f5ff;
}

.imagem-projeto {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageSite {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 1vh 1vh 1.5vh rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.imageSite:hover {
    transform: scale(1.05);
}

.botao-projeto {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1.2rem;
    color: white;
    text-transform: uppercase;
    background-color: #61dafb; 
    max-width: 10rem;
    margin-bottom: 1rem;
}

.botao-projeto.prod {
    background-color: #4CAF50; 
}

.botao-projeto.local {
    background-color: #585757;
}

.botao-ver {
  display: inline-block;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-color: #61dafbb6;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.botao-ver:hover {
  background-color: #61dafb;
}


@media (max-width: 768px) {
    .meus-projetos {
      padding: 2rem; 
      margin-top: 3rem; 
    }
  
    .text-projetos {
      padding-top: 2rem; 
      margin-bottom: 1rem; 
    }
  
    .title {
      font-size: 2rem; 
    }
  
    .projeto {
      width: 100%; 
      margin-bottom: 2rem; 
      flex-direction: column; 
    }
  
    .texto-projeto {
      padding: 1rem;
    }
  
    .texto-projeto h3 {
      font-size: 1.25rem; 
    }
  
    .texto-projeto p {
      font-size: 1rem; 
    }
  
    .imagem-projeto {
      width: 100%; 
      margin-bottom: 1rem; 
    }
  
    .imageSite {
      max-width: 100%; 
      height: auto; 
    }
  
    .botao-projeto {
      font-size: 1rem; 
      padding: 0.4rem 0.8rem; 
      max-width: 100%; 
      margin-bottom: 0.5rem;
    }

    .botao-ver {
      display: inline-block;
      margin-left: 1rem;
      padding: 0.4rem 0.8rem; 
      font-size: 1rem;
    }
  }