.footer {
  background-color: #000000;
  color: #fff;
  padding: 2vh;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin-top: 5rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-section {
  flex: 1;
  text-align: center;
}

.icon {
  transition: opacity 0.3s ease;
}

.email, .linkedin, .logoFooter {
  width: 5vw;
}

.email:hover {
  content: url('../assets/emailAzul.svg');
}

.linkedin:hover {
  content: url('../assets/linkedinAzul.svg');
}

/* .logoFooter:hover {
  content: url('../assets/RJR-Azul.svg');
} */

/* .email:hover,
.linkedin:hover {
  transform: scale(1.1); 
} */

@media (max-width: 768px) {
  .email, .linkedin, .logoFooter {
    width: 12vw; 
  }
}
