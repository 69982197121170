.dashboard-container {
    width: 90%;
    margin: 0 auto; 
    margin-top: 15vh;
  }
  
  .dashboard-content {
    margin-top: 2rem;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  
  .grid-item {
    border: 0.1vh solid rgb(95, 93, 93); 
    border-radius: 10px; 
    height: 15vh;
    /* background-color: rgba(255, 255, 255, 0.1); */
    overflow: hidden; 
  }
  
  .grid-content {
    padding: 1rem;
    text-align: center;
  }
  
  
  .large-grid {
    grid-template-columns: 70% 29%; 
    margin-top: 2vh;
  }

  .large-grid-item {
    border: 1px solid rgb(95, 93, 93);
    border-radius: 10px;
    height: 35vh;
    height: 95%; 
    overflow: hidden;
  }

  .small-grid-item {
    border: 1px solid rgb(95, 93, 93);
    border-radius: 10px;
    height: 75%; 
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .dashboard-container {
      width: 95%;
      margin: 0 auto;
      margin-top: 10rem; 
    }
  
    .dashboard-content {
      margin-top: 1rem;
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr); 
      grid-gap: 0.5rem;
    }
  
    .grid-item {
      height: auto;
      padding: 1rem;
    }
  
    .grid-content {
      padding: 0.5rem;
      text-align: center;
    }
  
    .large-grid {
      grid-template-columns: 100%;
      margin-top: 1.5vh;
    }
  
    .large-grid-item {
      height: 45vh; 
    }
  
    .small-grid-item {
      height: 35vh;
    }
  }
  