@media (max-width: 768px) {
        header {
            padding-bottom: 10vh; 
        }
    
        nav ul {
            list-style: none;
            display: flex;
            flex-direction: column; 
            gap: 1rem; 
            padding: 0;
            margin-top: 1rem; 
            margin-bottom: 0; 
        }
    
        .fixed-logo nav {
            display: none; 
        }
    
        .intro-text {
            text-align: center; 
        }
    
        .intro-text p {
            font-size: 22px; 
            padding-top: 100px;
            word-wrap: break-word;
        }
    
        .Logo {
            width: 300px;
        }
    
        .topicoMenu a {
            font-size: 14px;
        }

    }
    


body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    /* background-color: #f5f5f5; */
    background-color: black;
    color: white;
    max-width: 1800px;
    margin: 0 auto;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    padding: 2vh 0;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
}

.divMenu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10vh;
}

.Logo {
    padding: 2vh 1.5vw;
    transition: transform 0.5s ease;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 3vw;
    padding-right: 8vw;
}

.fixed-logo .Logo {
    padding: 1vh 1.5vw; 
    width: 8vw;
}

.fixed-logo nav {
    display: none; 
}

.topicoMenu a {
    text-decoration: none;
    color: white;
    font-size: 3vh;
    transition: color 0.3s; 
}

.topicoMenu a:hover {
    font-size: 3.2vh;
}

.intro-text {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 30vh;
    font-size: 4vh;
    line-height: 1.5;
}

.whatsapp-logo {
    position: fixed;
    left: 2vw;
    bottom: 2vh;
}

.whatsapp-logo img {
    width: 3vw;
    height: auto;
}

html {
    scroll-behavior: smooth;
}

.scroll-to-top {
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s;
}

.scroll-to-top:hover {
    opacity: 0.7;
}

.seta-icon {
    width: 4vh;
    height: 4vh;
}

footer {
    background-color: black;
    color: white;
    padding: 2vh;
    text-align: center;
}

@media (max-width: 768px) {
    .whatsapp-logo {
      left: 6vw; 
    }

    .seta-icon {
        width: 10vh; 
        height: 3vh; 
        padding-right: 4vw;
      }

      .fixed-logo .Logo {
        width: 25vw; 
      }

      .whatsapp-logo img {
        width: 10vw;
        height: auto;
    }
  }