.containerPP {
    padding: 2rem 5rem; 
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    background-color: #000;
    color: #e0e0e0; 
    border-radius: 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    margin-top: 3rem;
    margin-bottom: 8rem;
}

.titlePP {
    text-align: center;
    color: #58CCE9; 
    font-size: 3rem; 
    margin-bottom: 2rem;
}

.subtitlePP {
    font-size: 1.8rem; 
    margin-top: 2rem;
    color: #58CCE9; 
}

p {
    font-size: 1.2rem;
    line-height: 2rem; 
    color: #e0e0e0; 
}

.emailLinkPP {
    color: #58CCE9; 
    text-decoration: underline;
    cursor: pointer;
}

.emailLinkPP:hover {
    color: #45a8bf;
    text-decoration: none;
}

.listPP {
    padding-left: 1.5rem; 
    list-style-type: disc; 
    line-height: 2rem; 
    color: #e0e0e0;
}

@media (max-width: 768px) {
    .containerPP {
        padding: 1.5rem 2rem;
        max-width: 100%; 
        margin-top: 3rem;
        margin-bottom: 6rem;
    }

    .titlePP {
        font-size: 2.5rem; 
        margin-bottom: 1.5rem;
    }

    .subtitlePP {
        font-size: 1.5rem; 
        margin-top: 1.5rem;
    }

    p {
        font-size: 1.1rem; 
        line-height: 1.6rem; 
    }

    a {
        font-size: 1.1rem; 
    }

    .listPP {
        padding-left: 1rem; 
        line-height: 1.8rem; 
    }
}
