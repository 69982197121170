.chart-container {
  margin-top: 3rem; 
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 100%; 
  height: 100%; 
  box-sizing: border-box; 
}

canvas {
  width: 100% !important; 
  height: 100% !important;
}

@media (max-width: 768px) {
  .chart-container {
    margin-top: 1.5rem;
    padding: 10px;
    width: 100%;
    height: auto; 
    box-sizing: border-box;
  }

  canvas {
    width: 100% !important;
    height: 300px !important;
  }
}
