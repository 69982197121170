.home {
    display: grid;
    grid-template-columns: 1fr 35%;
    height: 60rem; 
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
    background: url('../assets/fundo2.jpg') no-repeat center top;
    background-size: cover;
    position: relative;
    overflow: hidden; 
    border-radius: 50px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
  
  .leftSection {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    z-index: 1;
    margin-left: 3rem;
  }
  
  .title {
    font-size: 3rem;
    font-weight: 600;
    color: white;
  }
  
  .subtitle {
    font-size: 1.5rem;
    color: #ddd;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .servicesList {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
    color: white;
  }
  
  .servicesList li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .servicesList li::before {
    content: "•";
    color: #58cce9;
    font-size: 2rem;
    display: inline-block;
    width: 1.5rem;
  }
  
  .requestButton {
    font-size: 1.2rem;
    padding: 0.7rem 2rem;
    background: linear-gradient(45deg, #58cce9, #58d5a9);
    color: #000000;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 2;
    width: 30%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 1s ease;
    animation: pulse 2s infinite;
}

.requestButton:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    background: linear-gradient(45deg, #58d5a9, #58cce9);
}

.requestButton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    transition: border-color 0.3s ease;
}

.requestButton:hover::after {
    border-color: rgba(255, 255, 255, 1);
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
}
  
  .rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 2rem;
  }
  
  .rightImage {
    margin-top: 10rem;
    width: 25rem; 
    height: 25rem;
    border-radius: 50%;
    object-fit: cover; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  @media (max-width: 768px) {
    .home {
        grid-template-columns: 1fr; 
        height: auto; 
        padding: 1rem;
        margin-top: 10rem;
    }

    .leftSection, .rightSection {
        margin-top: 2rem; 
        padding: 1rem; 
    }

    .title {
        font-size: 2rem; 
    }

    .subtitle {
        font-size: 1.2rem; 
    }

    .servicesList li {
        font-size: 1rem; 
    }

    .requestButton {
        width: auto; 
        padding: 0.5rem 1rem; 
    }

    .rightImage {
        width: 18rem; 
        height: 18rem;
        margin-top: 1rem; 
    }
}