@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vw;
    z-index: 1000;
}

.logoContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo {
    width: 5rem;
    transition: width 0.3s ease;
    height: auto;
    margin-top: 1rem;
    margin-left: 10rem;
    cursor: pointer;
}

.nav {
    flex: 2;
}

.navList {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    padding: 0;
    margin: 0;
    font-size: 1.3rem;
}

.navItem {
    display: flex;
    align-items: center;
}

.navLink {
    font-size: 1.3rem; 
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.navItem:hover {
    color: #58cce9; 
    cursor: pointer;
}

.menuIcon {
    display: none;
}

@media (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        transition: padding 0.3s ease, background-color 0.3s ease; 
    }

    .header.shrink {
        padding: 0.5rem 2rem;
    }

    .logoContainer {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    .logo {
        margin: 0;
        transition: max-width 0.3s ease; 
    }

    .header.shrink .logo {
        max-width: 150px;
    }

    .nav {
        display: none;
    }

    .menuIcon {
        display: block;
        cursor: pointer;
        color: #58cce9;
        font-size: 1.5rem;
        z-index: 1001;
    }

    .mobileMenu {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 1000;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
    }

    /* .mobileMenu.open {
        transform: translateY(0);
        background: url('../assets/fundo12.jpg') no-repeat center top;
    } */
    
    .mobileMenu.open {
        transform: translateY(0);
        /* background: url('../assets/fundo5.jpg') no-repeat center top; */
        background: url('../assets/fundo5.jpg') no-repeat center top;
        background-size: cover; 
        background-attachment: fixed; 
        background-position: center; 
        /* background-size: contain;  */
    }
    
    .navList {
        flex-direction: column;
        gap: 1.5rem;
    }

    .mobileNavLink {
        font-size: 2rem;
        text-decoration: none;
        transition: color 0.3s ease;
        color: #58cce9;
    }

    .mobileNavLink:hover {
        color: #106c83;
    }
}
