/* /////// TEST1 //////////////// */
.test1-container {
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }
  
  .test1-container h2 {
    margin-top: 0;
    text-align: left;
  }
  
  .contacts {
    font-size: 36px;
    font-weight: bold;
    margin-top: 10px;
    text-align: left; 
    color: rgb(95, 93, 93);
  }
  
  .difference {
    font-size: 16px;
    color: white; 
    text-align: left; 
  }
  
  .positive {
    color: green;
  }
  
  .negative {
    color: red;
  }
  
  /* /////// TEST1 //////////////// */

  /* /////// TEST2 //////////////// */
.test2-container {
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }
  
  .test2-container h2 {
    margin-top: 0;
    text-align: left; 
  }
/* /////// TEST2 //////////////// */

  /* /////// TEST3 //////////////// */
  .test3-container {
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }
  
  .test3-container h2 {
    margin-top: 0;
    text-align: left;
  }
/* /////// TEST3 //////////////// */


  /* /////// TEST4  //////////////// */
  .test4-container {
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }
  
  .test4-container h2 {
    margin-top: 0;
    text-align: left; 
  }
/* /////// TEST4  //////////////// */


@media (max-width: 768px) {
  .test1-container, .test2-container, .test3-container, .test4-container {
    padding: 15px;
    border-radius: 10px;
    text-align: center; 
  }

  .test1-container h2, .test2-container h2, .test3-container h2, .test4-container h2 {
    font-size: 1.2rem; 
    margin-top: 0;
  }

  .contacts {
    font-size: 28px; 
    margin-top: 5px;
  }

  .difference {
    font-size: 14px;
    margin-top: 10px;
  }

  .positive {
    color: green;
  }

  .negative {
    color: red;
  }
}
